import React, { useState } from 'react';
import { ReactComponent as TickSent } from '../../img/tick-sent.svg';
import { ReactComponent as Phone } from '../../img/phone.svg';
import { formatPrice } from '../utils';
import { sendRequestCallbackEmail } from '../email';

const MainResult = ({ totalPrice, options, howToEstimateClick }) => {
  const [email, setEmail] = useState('');
  const [isSent, setIsSent] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    sendRequestCallbackEmail({
      email,
      options
    });
    setIsSent(true);
  }

  return (
    <main className='main main--result'>
      <div className='main__container main__container--result'>
        <div className='main__result'>
          <div className='main__result-container'>
            <h1 className='main__result-title'>Your app estimate</h1>
            <span className='main__result-price'>
              {formatPrice(totalPrice)}*
            </span>
            <div className='main__result-comment'>
              <span className='main__result-asterisk'>* Estimate in AUD</span>
              <button
                className='main__result-button'
                onClick={howToEstimateClick}
              >
                How we got your estimate?
              </button>
            </div>
            {isSent ? (
              <aside className='main__sent'>
                <TickSent />
                <span>
                  Cost breakdown sent to {email}
                  <br />
                  Please check your spam folder.
                </span>
              </aside>
            ) : (
              <form className='main__feedback' onSubmit={handleSubmit}>
                <div className='main__feedback-item form-item'>
                  <input
                    className='main__feedback-input form-input'
                    type='email'
                    id='email'
                    placeholder='me@myemail.com'
                    value={email}
                    onChange={e => setEmail(e.currentTarget.value)}
                  />
                  <label
                    className='main__feedback-label form-label'
                    htmlFor='email'
                  >
                    My Email
                  </label>
                </div>
                <button
                  className='main__feedback-button button'
                  disabled={!email.length}
                >
                  Send me cost breakdown
                </button>
              </form>
            )}
          </div>
          <div className='main__info'>
            <p className='main__info-description'>
              Did you know that hiring the right developers can lower your cost
              of app development by <strong>30%</strong>? We are a Sydney based
              studio for direct hire.
            </p>
          </div>
        </div>
        <div className='main__svg'>
          <Phone />
        </div>

        <a
          className='main__info-button'
          target='_blank'
          rel='noopener noreferrer'
          href='https://studiolemnos.com.au'
        >
          VISIT STUDIO LEMNOS
        </a>
      </div>
    </main>
  );
};

export default MainResult;
