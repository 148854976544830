import React from 'react';
import { ReactComponent as ButtonYes } from '../../img/button-yes.svg';
import { ReactComponent as ButtonNo } from '../../img/button-no.svg';

const MainCalculator = ({ questionItem, onNoClick, onYesClick }) => {
  return (
    <main className='main'>
      <div className='main__container'>
        <div className='main__question-img'>
          <div className='main__question-svg'>{questionItem.svg}</div>
        </div>
        <h1 className='main__question-title'>{questionItem.title}</h1>
        <p className='main__question-desc'>{questionItem.desc}</p>

        <div className='main__question-buttons'>
          <button className='main__question-button main__question-button--no'>
            <ButtonNo onClick={onNoClick} />
          </button>
          <button className='main__question-button main__question-button--yes'>
            <ButtonYes onClick={onYesClick} />
          </button>
        </div>
      </div>
    </main>
  );
};

export default MainCalculator;
