import React from 'react';
import { ReactComponent as IconQuestionMark } from '../../img/icon-question-mark.svg';
import { ReactComponent as ButtonClose } from '../../img/button-close.svg';
import useLockBodyScroll from 'react-use/lib/useLockBodyScroll';

const NoPlatformsPopup = ({ onClose }) => {
  useLockBodyScroll();

  return (
    <section className='popup'>
      <div className='popup__wrapper'>
        <div className='popup__container'>
          <h1 className='popup__title'> App Calculator</h1>
          <ul className='popup__list'>
            <li className='popup__item'>
              <div className='popup__item-img'>
                <IconQuestionMark />
              </div>
              <div className='popup__desc'>
                <p className='popup__answer'>
                  You have not chosen any mobile platform. Please pick at least
                  one.
                </p>
              </div>
            </li>
          </ul>

          <button className='popup__form-button button' onClick={onClose}>
            Start again
          </button>

          <button className='popup__close-button' onClick={onClose}>
            <ButtonClose />
          </button>
        </div>
      </div>
    </section>
  );
};

export default NoPlatformsPopup;
