import React, { useState } from 'react';
import HeaderCalculator from '../header/calculator';
import MainCalculator from '../main/calculator';
import { calcTotalPrice, getPlatformsCount } from '../utils';
import NoPlatformsPopup from '../popup/no-platforms';
import questions from '../main/questions';

const FIRST_NON_PLATFORM_QUESTION = 2;

const AppCalculator = ({ onBack, onResult }) => {
  const [step, setStep] = useState(0);
  const [usedOptions, setUsedOptions] = useState([]);

  const question = questions[step];

  const totalPrice = calcTotalPrice(questions, usedOptions);

  const totalCount = questions.length;

  const showNoPlatformsPopup =
    getPlatformsCount(questions, usedOptions) === 0 &&
    step >= FIRST_NON_PLATFORM_QUESTION;

  return (
    <React.Fragment>
      <HeaderCalculator
        price={totalPrice}
        currentStep={step + 1}
        allSteps={totalCount}
        onBackClick={() => {
          if (step === 0) {
            return onBack();
          }

          const newStep = step - 1;

          setUsedOptions(usedOptions.filter(idx => idx !== newStep));
          setStep(newStep);
        }}
      />
      <MainCalculator
        questionItem={question}
        onNoClick={() => {
          const newStep = step + 1;

          if (newStep === totalCount) {
            return onResult({
              options: usedOptions,
              totalPrice
            });
          }

          setStep(newStep);
        }}
        onYesClick={() => {
          const newOptions = [...usedOptions, step];
          setUsedOptions(newOptions);

          const newStep = step + 1;

          if (newStep === totalCount) {
            return onResult({
              totalPrice: calcTotalPrice(questions, newOptions),
              options: newOptions
            });
          }

          setStep(newStep);
        }}
      />
      {showNoPlatformsPopup ? <NoPlatformsPopup onClose={onBack} /> : null}
    </React.Fragment>
  );
};

export default AppCalculator;
