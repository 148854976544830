import React, { useState } from 'react';
import HeaderResult from '../header/result';
import MainResult from '../main/result';
import Popup from '../popup/popup';

const AppResult = ({ onRestart, totalPrice, options }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <React.Fragment>
      <HeaderResult onRestartClick={onRestart} />
      <MainResult
        totalPrice={totalPrice}
        options={options}
        howToEstimateClick={() => setShowModal(true)}
      />
      {showModal ? (
        <Popup
          totalPrice={totalPrice}
          options={options}
          onClose={() => setShowModal(false)}
        />
      ) : null}
    </React.Fragment>
  );
};

export default AppResult;
