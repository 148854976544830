import questions from './main/questions';
import { formatPrice, calcTotalPrice, getOptionPriceFormatted } from './utils';

export function sendRequestCallbackEmail(data = {}) {
  const formData = new FormData();
  formData.append('email', data.email);
  const result = {
    Total_price: formatPrice(calcTotalPrice(questions, data.options))
  };
  questions.forEach((question, idx) => {
    result[question.emailId] = getOptionPriceFormatted(
      questions,
      data.options,
      idx
    );
  });
  formData.append('options', JSON.stringify(result));

  return fetch('/send.php', {
    method: 'POST',
    body: formData
  }).then(res => res.json());
}
