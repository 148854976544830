import React from 'react';
import { ReactComponent as IconIos } from '../../img/icon-ios.svg';
import { ReactComponent as IconAndroid } from '../../img/icon-android.svg';
import { ReactComponent as IconProfile } from '../../img/icon-profile.svg';
import { ReactComponent as IconPayments } from '../../img/icon-payments.svg';
import { ReactComponent as IconApi } from '../../img/icon-api.svg';
import { ReactComponent as IconInterface } from '../../img/icon-interface.svg';
import { ReactComponent as IconBluetooth } from '../../img/icon-bluetooth.svg';

const questions = [
  {
    title: 'Apple iOS Platform',
    desc:
      'Would you like to build an app for Apple iOS users that are more loyal than Android users?',
    basePrice: 8000,
    platformPrice: 0,
    isPlatform: true,
    svg: <IconIos />,
    emailId: 'iOS_price'
  },
  {
    title: 'Android Platform',
    desc:
      'Would you like to build an app for Android, the most popular platform in the Australia as well as Asia, Europe and North America?',
    basePrice: 8000,
    platformPrice: 0,
    isPlatform: true,
    svg: <IconAndroid />,
    emailId: 'Android_price'
  },
  {
    title: 'User Profiles and Login',
    desc:
      'Does your user require an account? Users could login with just an email and password or use other services, like Facebook and Google.',
    basePrice: 2000,
    platformPrice: 2500,
    svg: <IconProfile />,
    emailId: 'Profile_price'
  },
  {
    title: 'Payments',
    desc:
      'Would you like your app to generate revenue through one off download purchases or in-app purchases?',
    basePrice: 0,
    platformPrice: 2500,
    svg: <IconPayments />,
    emailId: 'Payment_price'
  },
  {
    title: 'API Integration',
    desc:
      'Does your app need to integrate with your own API or third-party services, such as Google Places, Campaign monitor, etc.',
    basePrice: 0,
    platformPrice: 3000,
    svg: <IconApi />,
    emailId: 'API_price'
  },
  {
    title: 'User Interface',
    desc:
      'Do you require a custom and unique design that makes your app unique to your users and stand up against its competition?',
    basePrice: 2000,
    platformPrice: 5000,
    svg: <IconInterface />,
    emailId: 'UI_price'
  },
  {
    title: 'Native Capabilities',
    desc: 'Does your app need to use the devices GPS, Camera or Bluetooth?',
    basePrice: 0,
    platformPrice: 3000,
    svg: <IconBluetooth />,
    emailId: 'NC_price'
  }
];

export default questions;
