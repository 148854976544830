/**
 * @param {*} questions
 * @param {number[]} used
 * @returns {number}
 */
export function calcTotalPrice(questions, used) {
  const platformsCount = getPlatformsCount(questions, used);

  return used
    .map(idx => questions[idx])
    .reduce((prev, cur) => prev + optionPrice(cur, platformsCount), 0);
}

/**
 * @param {*} questions
 * @param {number[]} used
 */
export function getPlatformsCount(questions, used) {
  return used.filter(idx => questions[idx].isPlatform).length;
}

/**
 * @param {*} questions
 * @param {number[]} used
 * @returns {string[]}
 */
export function getUsedOptionNames(questions, used) {
  return used.map(idx => questions[idx].title);
}

/**
 * @param {*} questions
 * @param {number[]} used
 * @param {number} optionIdx
 * @returns {number}
 */
function calcOptionPrice(questions, used, optionIdx) {
  const filtered = used.filter(idx => idx === optionIdx);
  if (filtered.length === 0) {
    return null;
  }

  const platformsCount = getPlatformsCount(questions, used);
  const option = questions[optionIdx];

  return optionPrice(option, platformsCount);
}

/**
 * @param {*} questions
 * @param {number[]} used
 * @param {number} optionIdx
 * @returns {number}
 */
export function getOptionPriceFormatted(questions, used, optionIdx) {
  const price = calcOptionPrice(questions, used, optionIdx);

  return price === null ? '-' : formatPrice(price);
}

function optionPrice(option, platformsCount) {
  return option.basePrice + option.platformPrice * platformsCount;
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
});

export function formatPrice(price) {
  return formatter.format(price);
}
