import React, { useState } from 'react';
import AppResult from './result';
import MainStart from '../main/start';
import AppCalculator from './calculator';

const App = () => {
  const [type, setType] = useState('start');
  const [totalPrice, setTotalPrice] = useState(0);
  const [options, setOptions] = useState([]);

  return (
    <div className='app'>
      <div className='app__container'>
        {type === 'start' ? (
          <MainStart onStartClick={() => setType('calc')} />
        ) : type === 'calc' ? (
          <AppCalculator
            onBack={() => setType('start')}
            onResult={({ totalPrice, options }) => {
              setTotalPrice(totalPrice);
              setOptions(options);
              setType('result');
            }}
          />
        ) : (
          <AppResult
            totalPrice={totalPrice}
            options={options}
            onRestart={() => setType('start')}
          />
        )}
      </div>
    </div>
  );
};

export default App;
