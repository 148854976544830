import React, { useState } from 'react';
import { ReactComponent as IconQuestionMark } from '../../img/icon-question-mark.svg';
import { ReactComponent as IconDirect } from '../../img/icon-pers.svg';
import { ReactComponent as TickSent } from '../../img/tick-sent.svg';
import { ReactComponent as ButtonClose } from '../../img/button-close.svg';
import { sendRequestCallbackEmail } from '../email';
import useLockBodyScroll from 'react-use/lib/useLockBodyScroll';
import questions from '../main/questions';
import { getOptionPriceFormatted, formatPrice } from '../utils';

const Popup = ({ onClose, totalPrice, options }) => {
  useLockBodyScroll();

  const [isSent, setIsSent] = useState(false);
  const [email, setEmail] = useState('');

  function handleSubmit(e) {
    e.preventDefault();

    sendRequestCallbackEmail({
      email,
      options
    });

    setIsSent(true);
  }

  return (
    <section className='popup'>
      <div className='popup__wrapper'>
        <div className='popup__container'>
          <h1 className='popup__title'>App Cost Calculator</h1>
          <ul className='popup__list'>
            <li className='popup__item'>
              <div className='popup__item-img'>
                <IconQuestionMark />
              </div>
              <div className='popup__desc'>
                <p className='popup__question'>How we got your estimate?</p>
                <p className='popup__answer'>
                  Your estimate is based on the pricing standards for digital
                  agencies in Sydney.
                </p>
              </div>
            </li>
            {questions.map((question, idx) => (
              <li className='popup__item' key={question.title}>
                <div className='popup__item-img'>{question.svg}</div>
                <div className='popup__desc'>
                  <p className='popup__question'>{question.title}</p>
                  <p className='popup__answer'>
                    {getOptionPriceFormatted(questions, options, idx)}
                  </p>
                </div>
              </li>
            ))}
            <li className='popup__item'>
              <div className='popup__item-img'>
                <IconDirect />
              </div>
              <div className='popup__desc'>
                <p className='popup__question'>Total:</p>
                <p className='popup__answer'>{formatPrice(totalPrice)}</p>
              </div>
            </li>
          </ul>

          {isSent ? (
            <aside className='main__sent'>
              <TickSent />

              <span>
                Cost breakdown sent to {email}
                <br />
                Please check your spam folder.
              </span>
            </aside>
          ) : (
            <form className='popup__form' onSubmit={handleSubmit}>
              <div className='form-item'>
                <input
                  className='form-input'
                  type='email'
                  id='email'
                  placeholder='me@myemail.com'
                  value={email}
                  onChange={e => setEmail(e.currentTarget.value)}
                />
                <label className='form-label' htmlFor='email'>
                  Email
                </label>
              </div>
              <button
                className='popup__form-button button'
                disabled={email.length === 0}
              >
                SEND ME COST BREAKDOWN
              </button>
            </form>
          )}

          <button className='popup__close-button' onClick={onClose}>
            <ButtonClose />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Popup;
