import React from 'react';
import { ReactComponent as ButtonBack } from '../../img/button-back.svg';
import { formatPrice } from '../utils';

const HeaderCalculator = ({ price, currentStep, allSteps, onBackClick }) => {
  return (
    <header className='header header--calculator'>
      <button className='header__button' type='button' onClick={onBackClick}>
        <ButtonBack />
      </button>
      <p className='header__price'>{formatPrice(price)}</p>
      <div className='header__step-progress'>
        {currentStep}/{allSteps}
      </div>
    </header>
  );
};

export default HeaderCalculator;
